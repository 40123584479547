<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">{{title}}</h3>
      </div>
    </div>

    <hr class="my-3">

    <sgv-form
      :method.sync="method"
      :options="options">
      <DetailForm
        :docType="docType"
        :method="method"
        :formData="formData"
        :v="$v">
      </DetailForm>
    </sgv-form>

    <DetailOrder
      :templateType="templateType"
      :docType="docType"
      :parentId="kpiTemplateId"
      :children="formData.children"
      :isEdit="method === 'edit'"
      @updated="dataDetail">
    </DetailOrder>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import DetailForm from './DetailForm.vue'
import DetailOrder from './DetailOrder.vue'
import {
  DETAIL_KPI_TEMPLATE, CREATE_KPI_TEMPLATE,
  UPDATE_KPI_TEMPLATE, DESTROY_KPI_TEMPLATE
} from './graph'

export default {
  metaInfo () {
    return {
      title: `${this.formData.name}` || `${this.title}`
    }
  },
  props: {
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    kpiTemplateId: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      formData: {
        code: '',
        name: '',
        children: [],
        isActive: true,
      },
      method: 'info',
      listView: `Doc${this.$form.capitalize(this.docType)}List`,
      isLoaded: false
    }
  },
  validations: {
    formData: {
      code: { required },
      name: { required },
    }
  },
  computed: {
    options () {
      return [
        {
          text: 'เพิ่ม',
          variant: 'success',
          method: 'add',
          func: this.createData,
          disabled: true
        },
        {
          text: 'แก้ไข',
          variant: 'warning',
          method: 'edit',
          func: this.updateData,
          disabled: !this.$auth.hasRole(`doc:${this.docType}:config`)
        },
        {
          text: 'ลบ',
          variant: 'danger',
          method: 'delete',
          func: this.destroyData,
          disabled: !this.$auth.hasRole(`doc:${this.docType}:config`)
        },
        {
          text: 'กลับ',
          variant: 'primary',
          func: this.closeForm,
          header: true,
          align: 'right'
        },
      ]
    }
  },
  methods: {
    dataDetail () {
      return this.$apollo.query({
        query: DETAIL_KPI_TEMPLATE(this.templateType),
        variables: {
          docType: this.docType,
          id: this.kpiTemplateId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        this.setFormData(res.data.item)
        return res.data.item
      })
      .catch(this.$toasted.global.error)
      .finally(() => {this.isLoaded = true})
    },
    setFormData (v) {
      Object.keys(this.formData).forEach(key => {
        this.formData[key] = v[key]
      })
    },
    serializeInput (v) {
      const input = {...v}
      delete input.children
      return input
    },
    createData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: CREATE_KPI_TEMPLATE(this.templateType),
        variables: {
          docType: this.docType,
          input
        }
      })
      .then(res => {
        this.method = 'info'
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$router.push({
          name: this.$route.name,
          params: {
            ...this.$route.params,
            kpiTemplateId: res.data.item.id
          },
          query: {...this.$route.query, redirect: this.listView}
        })
      })
      .catch(this.$toasted.global.error)
    },
    updateData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: UPDATE_KPI_TEMPLATE(this.templateType),
        variables: {
          docType: this.docType,
          id: this.kpiTemplateId,
          input
        }
      })
      .then(res => {
        this.method = 'info'
        this.setFormData(res.data.item)
        this.$toasted.global.success("แก้ไขสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    destroyData () {
      this.$apollo.mutate({
        mutation: DESTROY_KPI_TEMPLATE(this.templateType),
        variables: {
          docType: this.docType,
          id: this.kpiTemplateId
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.closeForm()
      })
      .catch(this.$toasted.global.error)
    },
    closeForm () {
      if (this.$route.query.redirect) {
        if (this.$route.query.redirect === this.$route.name) {
          this.$router.push({name: this.listView})
        } else {
          this.$router.push({name: this.$route.query.redirect})
        }
      } else {
        this.$router.push({name: this.listView})
      }
    },
  },
  created () {
    if (this.kpiTemplateId === 0) {
      this.method = 'add'
      this.isLoaded = true
    } else {
      this.dataDetail()
    }
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
  components: {
    DetailForm,
    DetailOrder
  }
}
</script>

<style lang="css" scoped>
</style>
